import React from "react"
import qlImage from "../../images/ql-thumbnail.png"
import olivoImage from "../../images/olivo-thumbnail.png"
import tographyImage from "../../images/tography-thumbnail.png"
import visualComposerImage from "../../images/wpb-logo.png"
import sliderRevolutionImage from "../../images/revolution-slider.png"
import addonsImage from "../../images/wp-bakery-addons.jpg"

const PluginsPage = () => {
    return (
        <div>
            <header className="page-header entry-header">
                <h1 className="page-title entry-title">Plugins</h1>
            </header>

            <div className="page-content">
                <div className="entry-content">
                    <div className="plugins-download">
                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img
                                    src={sliderRevolutionImage}
                                    alt="Slider Revolution"
                                />
                                <h4>Slider Revolution</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/revolution-slider/revslider.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://www.themepunch.com/support-center/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>
                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img
                                    src={visualComposerImage}
                                    alt="WPBakery Page Builder"
                                />
                                <h4>WPBakery Page Builder</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/visual-composer/js_composer.zip"
                                    download
                                    rel="noopener noreferrer"
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://kb.wpbakery.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>

                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img src={addonsImage} alt=" <h4>" />
                                <h4>
                                    Ultimate Addons for WPBakery Page Builder
                                </h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/visual-composer/Ultimate_VC_Addons.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://docs.brainstormforce.com/category/uavc/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>

                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img src={olivoImage} alt="Olivo Shortcodes" />
                                <h4>Olivo Shortcodes</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/olivo-shortcodes/olivo-shortcodes.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://quemalabs.ticksy.com/articles/100008674/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>

                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img
                                    src={qlImage}
                                    alt="Quema Labs Shortcodes"
                                />
                                <h4>Quema Labs Shortcodes</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/quema-labs-shortcodes/quema-labs-shortcodes.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://quemalabs.ticksy.com/articles/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>

                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img
                                    src={qlImage}
                                    alt="Quema Labs Slider Post Types"
                                />
                                <h4>Quema Labs Slider Post Types</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/quemalabs-slider-post-type/quemalabs-slider-post-type.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://quemalabs.ticksy.com/articles/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>

                        <div className="plugin-download">
                            <div className="plugin-download-header">
                                <img
                                    src={tographyImage}
                                    alt="Tography Shortcodes"
                                />
                                <h4>Tography Shortcodes</h4>
                            </div>
                            <div className="plugin-download-actions">
                                <a
                                    href="https://admin.quemalabs.com/files/plugins/tography-shortcodes/tography-shortcodes.zip"
                                    rel="noopener noreferrer"
                                    download
                                    className="secondary-icon-button"
                                >
                                    {svgDownload()}
                                    Download
                                </a>
                                <a
                                    href="https://quemalabs.ticksy.com/articles/10124/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentation-button"
                                >
                                    Docs
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function svgDownload() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Two_Tone"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                style={{
                    enableBackground: "new 0 0 24 24",
                }}
            >
                <path d="M12,24l-4-4h3v-8h2v8h3L12,24z" />
                <path d="M18.705,7.004C17.839,4.056,15.149,2,12,2C9.709,2,7.561,3.138,6.258,5.004C2.785,5.132,0,7.997,0,11.5  C0,15.084,2.916,18,6.5,18H9v-2H6.5C4.019,16,2,13.981,2,11.5S4.019,7,6.5,7l0.859,0.047l0.308-0.533C8.559,4.963,10.22,4,12,4  c2.472,0,4.545,1.773,4.932,4.215l0.151,0.96l1.098-0.143C18.286,9.018,18.391,9,18.5,9c1.93,0,3.5,1.57,3.5,3.5S20.43,16,18.5,16  H15v2h3.5c3.032,0,5.5-2.467,5.5-5.5C24,9.536,21.643,7.112,18.705,7.004z" />
                <path
                    style={{
                        opacity: 0.3,
                    }}
                    d="M3.343,16h17.953C22.327,15.175,23,13.922,23,12.5c0-2.485-2.015-4.5-4.5-4.5  c-0.199,0-0.389,0.034-0.581,0.059C17.466,5.193,14.993,3,12,3C9.774,3,7.836,4.215,6.8,6.015C6.7,6.01,6.601,6,6.5,6  C3.462,6,1,8.462,1,11.5C1,13.362,1.928,15.005,3.343,16z"
                />
            </svg>
        )
    }
}

export default PluginsPage
