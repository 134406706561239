import React, { useState } from "react"
import { navigate } from "@reach/router"

export default ({ handleSubmit, handleUpdate }) => {
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        usernameError: false,
        passwordError: false,
    })

    return (
        <form
            method="post"
            onSubmit={async event => {
                setLoading(true)
                setErrors({ usernameError: false, passwordError: false })

                let submitResponse = await handleSubmit(event)

                if (submitResponse != null) {
                    // check for errors
                    if (
                        submitResponse.hasOwnProperty("usernameError") &&
                        true === submitResponse.usernameError
                    ) {
                        setErrors({ usernameError: true })
                        setLoading(false)
                        return false
                    } else if (
                        submitResponse.hasOwnProperty("passwordError") &&
                        true === submitResponse.passwordError
                    ) {
                        setErrors({ passwordError: true })
                        setLoading(false)
                        return false
                    }
                }

                if (submitResponse.token) {
                    // Animation
                    var element = document.getElementById("main")
                    element.classList.add("animation")

                    setTimeout(function() {
                        navigate(`/app/purchases`)
                    }, 500)
                } else {
                    setLoading(false)
                }
            }}
            className="login-form"
        >
            <div
                className={
                    "input-wrap " + (errors.usernameError ? "error" : "")
                }
            >
                <label className="control-label" htmlFor="username">
                    Username
                </label>
                <div className="controls-wrap">
                    <input
                        className="input-xlarge"
                        type="text"
                        name="username"
                        id="username"
                        size="22"
                        tabIndex="1"
                        aria-required="true"
                        placeholder="John Doe"
                        onChange={handleUpdate}
                        required
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 24"
                        style={{ enableBackground: "new 0 0 24 24" }}
                    >
                        <path
                            style={{ opacity: 0.3, enableBackground: "new" }}
                            d="M18.6,15.6c-2-1-4.8-1.6-6.6-1.6c-3.1,0-9,1.5-9,4.5V21h10.2L18.6,15.6z"
                        />
                        <path d="M15.2,19H5v-0.5c0-0.9,3.7-2.5,7-2.5c1.8,0,3.7,0.5,5.1,1.1l1.5-1.5c-2-1-4.8-1.6-6.6-1.6c-3.1,0-9,1.5-9,4.5V21h10.2  L15.2,19z" />
                        <circle
                            style={{ opacity: 0.3, enableBackground: "new" }}
                            cx="12"
                            cy="7"
                            r="3"
                        />
                        <path d="M12,11c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S14.2,11,12,11z M12,5c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,5,12,5z" />
                        <path d="M20.1,16.9l2,2L17,24h-2v-2L20.1,16.9z M21.9,15.1l-1,1l2,2l1-1c0.2-0.2,0.2-0.5,0-0.7l-1.3-1.3C22.4,15,22,15,21.9,15.1z" />
                    </svg>
                </div>
                <span className="input-error">Wrong username</span>
            </div>

            <div
                className={
                    "input-wrap " + (errors.passwordError ? "error" : "")
                }
            >
                <label className="control-label" htmlFor="password">
                    Password
                </label>
                <div className="controls-wrap">
                    <input
                        className="input-xlarge"
                        type="password"
                        name="password"
                        id="password"
                        size="22"
                        tabIndex="2"
                        aria-required="true"
                        placeholder="Password"
                        onChange={handleUpdate}
                        required
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 24"
                        style={{ enableBackground: "new 0 0 24 24" }}
                    >
                        <g style={{ opacity: 0.3 }}>
                            <circle cx="12" cy="15" r="7" />
                        </g>
                        <path d="M18,10h-2V7c0-2.2-1.8-4-4-4C9.8,3,8,4.8,8,7v3H6V7c0-3.4,2.6-6,6-6c3.4,0,6,2.6,6,6V10z" />
                        <path d="M12,7c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,7,12,7z M13,15.7V19h-2v-3.3c-0.6-0.3-1-1-1-1.7c0-1.1,0.9-2,2-2  s2,0.9,2,2C14,14.7,13.6,15.4,13,15.7z M18,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6S18,11.7,18,15z" />
                    </svg>
                </div>
                <span className="input-error">Wrong password</span>
            </div>

            <div className="form-submit">
                <button type="submit" className={loading ? "loading" : ""}>
                    Log In
                </button>
            </div>

            <div className="forgot-wrap">
                <a
                    href={`${process.env.GATSBY_WP_URL}wp-login.php?action=lostpassword`}
                    rel="noopener noreferrer"
                    className="forgot-link"
                >
                    Forgot your password?
                </a>
            </div>
        </form>
    )
}
