import { isLoggedIn, getCurrentUser } from "./auth"

export default async function(apiRoute, method = "GET") {
    if (!isLoggedIn) {
        return false
    }
    const { token } = getCurrentUser()
    var headers = new Headers()
    let userToken = "Bearer " + token
    headers.append("Authorization", userToken)
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")

    let restUrl = process.env.GATSBY_WP_URL + `wp-json/` + apiRoute

    let response = await fetch(restUrl, {
        method: method,
        headers: headers,
    })
        .then(async response => {
            // Verificamos que obtuvimos una respuesto en json sino devolvemos como texto
            const contentType = response.headers.get("content-type")
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return await response.json()
            } else {
                return await response.text()
            }
        })
        .then(async responseJson => {
            return await responseJson
        })
        .catch(error => {
            console.error(error)
        })

    return response
}
