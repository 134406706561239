import React from "react"
import { getCurrentUser } from "../../utils/auth"

const Details = () => {
    const { user_display_name, user_email, user_nicename } = getCurrentUser()

    return (
        <div>
            <header className="page-header entry-header">
                <h1 className="page-title entry-title">Details</h1>
            </header>

            <div className="page-content">
                <div className="entry-content">
                    <ul>
                        <li>Preferred name: {user_nicename}</li>
                        <li>Legal name: {user_display_name}</li>
                        <li>Email address: {user_email}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Details
