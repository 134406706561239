import React from "react"
import { getCurrentUser } from "../../utils/auth"

const Home = () => {
    const { user_nicename } = getCurrentUser()

    return (
        <div>
            <header className="page-header entry-header">
                <h1 className="page-title entry-title">Profile</h1>
            </header>

            <div className="page-content">
                <div className="entry-content">
                    <p>Welcome back, {user_nicename}!</p>
                </div>
            </div>
        </div>
    )
}

export default Home
