import React from "react"
import { navigate } from "gatsby"
import Form from "./LoginForm"
import { handleLogin, isLoggedIn } from "../../utils/auth"

class Login extends React.Component {
    state = {
        username: ``,
        password: ``,
    }

    handleUpdate(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    async handleSubmit(event) {
        event.preventDefault()
        return await handleLogin(this.state)
    }

    render() {
        if (isLoggedIn()) {
            navigate(`/app/profile`)
        }

        return (
            <div className="login-wrap">
                <header className="page-header entry-header">
                    <h1 className="page-title entry-title">Login</h1>
                </header>

                <div className="page-content">
                    <div className="entry-content">
                        <Form
                            handleUpdate={e => this.handleUpdate(e)}
                            handleSubmit={e => this.handleSubmit(e)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
