import { Link } from "gatsby"
import React, { useEffect } from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Details from "../components/App/Details"
import Home from "../components/App/Profile"
import Login from "../components/App/Login"
import Purchases from "../components/App/Purchases"
import PluginsPage from "../components/App/PluginsPage"
import PrivateRoute from "../components/App/PrivateRoute"
import { isLoggedIn } from "../utils/auth"

const App = props => {
    console.log(props.location.pathname)
    // De forma similar a componentDidMount y componentDidUpdate
    useEffect(() => {
        var element = document.getElementById("main")
        element.classList.remove("animation")
    })
    return (
        <Layout cssClasses="app page">
            {isLoggedIn() && (
                <ul className="app-sections-menu">
                    <li
                        className={
                            props.location.pathname.includes("/app/purchases")
                                ? "active"
                                : ""
                        }
                    >
                        <Link to="/app/purchases/">Purchases</Link>
                    </li>
                    <li
                        className={
                            props.location.pathname.includes("/app/plugins")
                                ? "active"
                                : ""
                        }
                    >
                        <Link to="/app/plugins/">Plugins</Link>
                    </li>
                </ul>
            )}

            <article className={`page type-page status-publish hentry entry`}>
                <Router>
                    <PrivateRoute path="/app/profile" component={Home} />
                    <PrivateRoute path="/app/details" component={Details} />
                    <PrivateRoute
                        path="/app/plugins/"
                        component={PluginsPage}
                    />
                    <PrivateRoute
                        path="/app/purchases/"
                        component={Purchases}
                    />
                    <Login path="/app/login" />
                </Router>
            </article>
        </Layout>
    )
}
export default App
