import React from "react"
// import { navigate } from "gatsby"
// import { isLoggedIn, getCurrentUser } from "../../utils/auth"
import fetchApi from "../../utils/fetchApi"
import moment from "moment/moment"
import paypalImage from "../../images/paypal_logo.png"
import SEO from "../seo"

class Purchases extends React.Component {
    state = {
        loading: false,
        error: false,
        purchases: [],
    }

    componentDidMount() {
        this.fetchPurchases()
    }

    render() {
        const { purchases } = this.state
        console.log(purchases)
        console.log("purchases")

        return (
            <div>
                <SEO title="Purchases" />
                <header className="page-header entry-header">
                    <h1 className="page-title entry-title">Purchases</h1>
                </header>

                <div className="page-content">
                    <div className="entry-content">
                        <div className="history-purchases">
                            {(() => {
                                if (this.state.loading) {
                                    return <p>Loading...</p>
                                } else if (purchases.length > 0) {
                                    return (
                                        <React.Fragment>
                                            {purchases.map(
                                                (purchaseItem, index) => (
                                                    <div
                                                        className="purchase-item"
                                                        key={index}
                                                    >
                                                        {purchaseItem.download_image && (
                                                            <div className="purchase-image">
                                                                <img
                                                                    src={
                                                                        purchaseItem.download_image
                                                                    }
                                                                    className="attachment-theme size-theme"
                                                                    alt={
                                                                        purchaseItem.purchase_name
                                                                    }
                                                                />
                                                            </div>
                                                        )}

                                                        <div className="purchase-content">
                                                            <div className="purchase-meta">
                                                                <span className="edd_purchase_date">
                                                                    {moment(
                                                                        purchaseItem.purchase_date
                                                                    ).format(
                                                                        `MMMM D, YYYY`
                                                                    )}
                                                                </span>
                                                                <span className="edd_purchase_id">
                                                                    <a
                                                                        href={`https://www.quemalabs.com/checkout/purchase-confirmation/?payment_key=${purchaseItem.payment_key}`}
                                                                    >
                                                                        #
                                                                        {
                                                                            purchaseItem.id
                                                                        }
                                                                    </a>
                                                                </span>
                                                                <a
                                                                    href={`https://www.quemalabs.com/checkout/purchase-confirmation/?payment_key=${purchaseItem.payment_key}`}
                                                                    className="purchase-recepit"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 16 16"
                                                                        version="1.1"
                                                                        fill="#6c757d"
                                                                    >
                                                                        <g
                                                                            id="surface1"
                                                                            fill="#6c757d"
                                                                        >
                                                                            <path
                                                                                d="M 7.5 1.941406 L 5.472656 2.953125 L 3 2.015625 L 3 10 L 2.523438 10 C 1.699219 10 1.023438 10.675781 1.023438 11.5 L 1 13.996094 L 2 14.003906 L 2.023438 11.5 C 2.023438 11.21875 2.238281 11 2.523438 11 L 3 11 L 3 13 L 12 13 L 12 11 L 12.5 11 C 12.78125 11 13 11.21875 13 11.5 L 13 14 L 14 14 L 14 11.5 C 14 10.675781 13.324219 10 12.5 10 L 12 10 L 12 2.015625 L 9.527344 2.953125 Z M 7.5 3.058594 L 9.472656 4.046875 L 9.679688 3.96875 L 11 3.464844 L 11 12 L 4 12 L 4 3.464844 L 5.527344 4.046875 Z M 5 6 L 5 7 L 7.992188 7.007813 L 7.992188 6.007813 Z M 9.003906 6 L 8.996094 7 L 9.996094 7.007813 L 10.003906 6.007813 Z M 5 9 L 5 10 L 8 10 L 8 9 Z M 9 9 L 9 10 L 10 10 L 10 9 Z "
                                                                                fill="#6c757d"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                    Receipt
                                                                </a>
                                                            </div>

                                                            <div className="purchase-entry">
                                                                <h3 className="purchase-name">
                                                                    {
                                                                        purchaseItem.purchase_name
                                                                    }
                                                                </h3>
                                                                <span className="edd_purchase_amount">
                                                                    $
                                                                    {
                                                                        purchaseItem.purchase_amount
                                                                    }
                                                                </span>

                                                                <div
                                                                    className={`edd_receipt_payment_status ${purchaseItem.status}`}
                                                                >
                                                                    <span className="payment-status">
                                                                        {
                                                                            purchaseItem.status_nicename
                                                                        }
                                                                    </span>
                                                                    {"paypal" ===
                                                                        purchaseItem.payment_gateway && (
                                                                        <span className="payment-paypal">
                                                                            <img
                                                                                src={
                                                                                    paypalImage
                                                                                }
                                                                                alt="PayPal"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </div>

                                                                <div className="clearfix"></div>

                                                                <div className="purchase-licenses">
                                                                    {purchaseItem.licenses instanceof
                                                                        Array &&
                                                                        purchaseItem
                                                                            .licenses
                                                                            .length >
                                                                            0 &&
                                                                        purchaseItem.licenses.map(
                                                                            (
                                                                                licenseItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        className="purchase-license"
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <span className="purchase-license-name">
                                                                                            {
                                                                                                licenseItem.name
                                                                                            }
                                                                                            {` `}
                                                                                            License
                                                                                        </span>
                                                                                        <span className="purchase-key">
                                                                                            {
                                                                                                licenseItem.key
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                    {/* <a
                                                                        href="/checkout/purchase-history/?action=manage_licenses&amp;payment_id=374"
                                                                        className="purchase-expire"
                                                                    >
                                                                        View
                                                                        Licenses
                                                                    </a> */}
                                                                </div>

                                                                <ul className="edd_purchase_receipt_files">
                                                                    {purchaseItem
                                                                        .cart[0]
                                                                        .download_files instanceof
                                                                        Array &&
                                                                        purchaseItem
                                                                            .cart[0]
                                                                            .download_files
                                                                            .length >
                                                                            0 &&
                                                                        purchaseItem.cart[0].download_files.map(
                                                                            (
                                                                                downloadFile,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <li
                                                                                        className="edd_download_file"
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <a
                                                                                            href={
                                                                                                downloadFile.download_url
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            className="edd_download_file_link"
                                                                                        >
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                version="1.1"
                                                                                                id="Two_Tone"
                                                                                                x="0px"
                                                                                                y="0px"
                                                                                                viewBox="0 0 24 24"
                                                                                                style={{
                                                                                                    enableBackground:
                                                                                                        "new 0 0 24 24",
                                                                                                }}
                                                                                            >
                                                                                                <path d="M12,24l-4-4h3v-8h2v8h3L12,24z" />
                                                                                                <path d="M18.705,7.004C17.839,4.056,15.149,2,12,2C9.709,2,7.561,3.138,6.258,5.004C2.785,5.132,0,7.997,0,11.5  C0,15.084,2.916,18,6.5,18H9v-2H6.5C4.019,16,2,13.981,2,11.5S4.019,7,6.5,7l0.859,0.047l0.308-0.533C8.559,4.963,10.22,4,12,4  c2.472,0,4.545,1.773,4.932,4.215l0.151,0.96l1.098-0.143C18.286,9.018,18.391,9,18.5,9c1.93,0,3.5,1.57,3.5,3.5S20.43,16,18.5,16  H15v2h3.5c3.032,0,5.5-2.467,5.5-5.5C24,9.536,21.643,7.112,18.705,7.004z" />
                                                                                                <path
                                                                                                    style={{
                                                                                                        opacity: 0.3,
                                                                                                    }}
                                                                                                    d="M3.343,16h17.953C22.327,15.175,23,13.922,23,12.5c0-2.485-2.015-4.5-4.5-4.5  c-0.199,0-0.389,0.034-0.581,0.059C17.466,5.193,14.993,3,12,3C9.774,3,7.836,4.215,6.8,6.015C6.7,6.01,6.601,6,6.5,6  C3.462,6,1,8.462,1,11.5C1,13.362,1.928,15.005,3.343,16z"
                                                                                                />
                                                                                            </svg>
                                                                                            {
                                                                                                downloadFile.download_file_name
                                                                                            }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <p>Oh noes, error fetching pupper :(</p>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // This data is fetched at run time on the client.
    fetchPurchases = async () => {
        this.setState({ loading: true })

        let fetchResponse = await fetchApi(
            "quema-labs/v1/get-purchases",
            "POST"
        )

        this.setState({ purchases: fetchResponse, loading: false })
    }
}

export default Purchases
